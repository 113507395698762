import { motion } from "framer-motion";
import { CustomButtonLink } from "~/components/ui/CustomButton";
import { ReactNode } from "react";

export function Shop() {
  return (
    <section
      className="flex scroll-mt-16 flex-col items-center justify-center overflow-x-clip px-5 pb-24 pt-16"
      id="shop"
    >
      <div className="flex w-[1198px] max-w-full flex-col">
        <h2 className="self-center text-center text-white text-m-h2 sm:text-h2">
          AUF DER SUCHE NACH
          <br className="sm:hidden" /> NACHSCHUB?
        </h2>
        <div className="relative">
          <div className="mt-16 grid min-h-[480px] w-full grid-cols-2 justify-center gap-6 text-lg text-white opacity-30 blur-[1.5px] max-md:mt-10 max-md:max-w-full lg:grid-cols-4">
            <ProductItem
              name="VOLL Weinschorle (0,33ml)"
              price="1,99 € / Dose"
              image="/images/shop/1_Weinschorle_Front.webp"
            />
            <ProductItem
              name="VOLL Roséschorle (0,33ml)"
              price="1,99 € / Dose"
              image="/images/shop/2-Roséschorle_Front.webp"
            />
            <ProductItem
              name="VOLL Mineralwasser (0,33ml)"
              price="1,99 € / Dose"
              image="/images/shop/3_Wasser_Front.webp"
              className="max-lg:hidden"
            />
            <ProductItem
              name="VOLL Probierpaket (6 x 0,33ml)"
              price="10,99 € / Karton"
              image="/images/shop/Frame_5126.webp"
              className="max-lg:hidden"
            />
          </div>
          <Overlay />
        </div>
      </div>
    </section>
  );
}

function Overlay() {
  return (
    <div className="absolute inset-0 z-20 flex flex-col items-center justify-center">
      {/* Sold Out Bands */}
      <LineBand className="-rotate-[10deg] sm:-rotate-[3.5deg]" />
      <LineBand className="rotate-[3.5deg] sm:rotate-[3.5deg]" reverse={true} />

      {/* Information Text */}
      <p className="my-6 max-w-80 py-3 text-center text-white text-m-pm sm:text-pl">
        Der erste Drop ist leider bereits vergriffen. Für geschäftliche
        Anfragen, nutze gern unser Kontaktformular.
      </p>

      {/* Button */}
      {/*todo backdrop blur und transparent bg on hover?*/}
      <CustomButtonLink
        to={{
          pathname: "/",
          hash: "#kontakt",
        }}
        className=""
      >
        Geschäftskunden hier entlang
      </CustomButtonLink>
    </div>
  );
}

function LineBand({
  className,
  reverse = false,
}: {
  className?: string;
  reverse?: boolean;
}) {
  return (
    <span
      className={`${className} mb-4 flex w-full min-w-[50rem] max-w-[75rem] transform justify-center overflow-hidden text-nowrap bg-white px-4 pb-1 pt-4 text-black text-m-h5 sm:origin-right sm:text-h5`}
    >
      {Array(14)
        .fill(0)
        .map((_, index) => (
          <TranslateWrapper key={index} reverse={reverse}>
            AUSVERKAUFT
          </TranslateWrapper>
        ))}
    </span>
  );
}

const TranslateWrapper = ({
  children,
  reverse,
}: {
  children: ReactNode;
  reverse?: boolean;
}) => {
  return (
    <motion.div
      initial={{ translateX: reverse ? "-50%" : "50%" }}
      animate={{ translateX: reverse ? "50%" : "-50%" }}
      transition={{ duration: 5, repeat: Infinity, ease: "linear" }}
      className="flex select-none px-2"
    >
      {children}
    </motion.div>
  );
};

type ProductItemProps = {
  name: string;
  price: string;
  image: string;
  className?: string;
};

function ProductItem({ name, price, image, className }: ProductItemProps) {
  return (
    <div
      className={`${className} flex aspect-[0.7] flex-1 shrink basis-0 select-none flex-col`}
    >
      <img
        src={image}
        className="aspect-[0.7] w-full flex-1 object-contain"
        alt={name + "Dose"}
        loading="lazy"
      />
      <div className="flex w-full flex-col space-y-2 p-4 sm:space-y-6">
        <span className="text-m-pm max-sm:text-center sm:text-pl">{name}</span>
        <div className="flex max-sm:flex-col-reverse max-sm:text-center sm:justify-between">
          <span className="text-m-pt sm:text-pxs">
            6,60 € / 1 L <br className="max-sm:hidden" /> + 0,25 € Pfand
          </span>
          <span className="text-m-pm sm:text-pl">{price}</span>
        </div>
      </div>
    </div>
  );
}
