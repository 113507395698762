export function Team() {
  return (
    <section
      className="flex scroll-mt-16 flex-col items-center justify-center px-5 py-16"
      id="team"
    >
      <div className="flex w-[1198px] max-w-full flex-col">
        <h2 className="self-center text-center uppercase text-white text-m-h2 sm:text-h2">
          Das Team hinter der Dose
        </h2>
        <div className="mt-16 grid min-h-[480px] w-full grid-cols-2 justify-center gap-6 text-lg text-white max-md:mt-10 max-md:max-w-full lg:grid-cols-4">
          <TeamMember
            name="Henri Olbrich"
            task="Geschäftsführer, Sales"
            image="/images/team/Henri.webp"
          />
          <TeamMember
            name="Benjamin Vettel"
            task="Geschäftsführer"
            image="/images/team/Benjamin.webp"
          />
          <TeamMember
            name="Marco Mautry"
            task="Product and Shipping Manager"
            image="/images/team/Marco.webp"
          />
          <TeamMember
            name="Johanna Karrasch"
            task="Marketing and Social Media"
            image="/images/team/Johanna.webp"
          />
        </div>
      </div>
    </section>
  );
}

type TeamMemberProps = {
  name: string;
  task: string;
  image: string;
};

function TeamMember({ name, task, image }: TeamMemberProps) {
  return (
    <div className="flex aspect-[0.7] flex-1 shrink basis-0 flex-col">
      <img
        loading="lazy"
        src={image}
        className="aspect-[0.7] w-full flex-1 object-cover"
        alt={"Teammitglied " + name}
      />
      <div className="mt-6 flex w-full flex-col text-m-pm sm:text-pl">
        {/*todo font bold*/}
        <span className="font-bold">{name}</span>
        <span className="">{task}</span>
      </div>
    </div>
  );
}
