import { CustomButton, CustomButtonLink } from "~/components/ui/CustomButton";

type VibesProps = {};

export function Vibes({}: VibesProps) {
  return (
    <section
      className="flex w-full scroll-mt-16 flex-col items-center px-5 py-16"
      id="spirit"
    >
      <img
        className="mx-auto"
        src="/images/ThirstyGoodVibes.svg"
        loading="lazy"
        alt="Thirsty for good vibes"
      />
      <h1 className="max-w-[55rem] pt-20 text-center text-m-h1 md:text-h1">
        Getränke neu gedacht. <br /> Was gibt es nicht? Was wünscht man sich?
      </h1>
      <p className="max-w-[55rem] pt-6 text-center text-m-pm md:text-pl">
        Getränke in der Dose sind nichts Neues. Doch wir wollten etwas
        Besonderes wagen. Mit unserer „Voll Weinschorle“ bringen wir einen
        Klassiker in die Dose. Praktisch für zuhause und unterwegs – für
        unvergessliche Momente allein oder mit Freunden.
      </p>

      <CustomButtonLink
        to={{
          pathname: "/",
          hash: "#kontakt",
        }}
        className="mt-14"
      >
        VOLL Weinschorle bestellen
      </CustomButtonLink>
    </section>
  );
}
