export function Hero() {
  return (
    <section className="relative min-h-[calc(100vh-73px)] w-full overflow-x-clip">
      {/*todo optimize video*/}

      <video
        className="absolute left-0 top-0 h-full w-full object-cover"
        autoPlay
        loop
        muted
        playsInline
        preload="none" // Prevent preloading the video before it's in view
      >
        <source src="/videos/Launch-Video.webm" type="video/webm" />
        <source src="/videos/Launch-Video.mp4" type="video/mp4" />
        <p>
          Your browser doesn't support HTML video. Here is a
          <a
            href="/videos/Launch-Video.mp4"
            download="UPdrins-Launch-Video.mp4"
          >
            link to the video
          </a>
          instead.
        </p>
      </video>
    </section>
  );
}
