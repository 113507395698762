import { ButtonHTMLAttributes, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { CustomButtonLink } from "~/components/ui/CustomButton";

export function Drinks() {
  const drinks = [
    {
      name: "Weinschorle",
      description:
        "Hallo! Ich bin die klassische Weinschorle. Der perfekte Mix aus deutschem Wein und natürlichem Mineralwasser macht mich unverwechselbar. Ich bin wie gemacht für jeden Anlass und bringe durch meinen leichten und spritzigen Geschmack die eiskalte Erfrischung aus der Dose!",
      imageSrc: "/images/drinks/Weinschorle_Front.webp",
      textColor: "text-[#92D600]",
      dropShadow:
        "chrome:drop-shadow-[0px_0px_79px_rgba(154,212,46,0.4)] firefox:drop-shadow-[0px_0px_79px_rgba(154,212,46,0.4)]",
    },
    {
      name: "Roséschorle",
      description:
        "Hey! Ich bin die spritzige Roséweinschorle! Perfekt für sonnige Tage und Partys, bringe ich gute Laune und fruchtigen Geschmack. Genieße mich am besten gekühlt und lass dich von meinem leichten, verspielten Charakter erfrischen!",
      imageSrc: "/images/drinks/Roséschorle_Front.webp",
      textColor: "text-[#EC6B5E]",
      dropShadow:
        "chrome:drop-shadow-[0px_0px_79px_rgba(231,96,96,0.4)] firefox:drop-shadow-[0px_0px_79px_rgba(231,96,96,0.4)]",
    },
    {
      name: "Mineralwasser",
      description:
        "Hey! Ich bin das prickelnde Mineralwasser, passend für jeden Anlass und dein Helfer gegen den Durst. Erfrischend und klar bin ich die perfekte Ergänzung im Voll Sortiment und biete eine sanfte Abwechslung zu den spritzigen Weinschorlen!",
      imageSrc: "/images/drinks/Wasser_Front.webp",
      textColor: "text-[#00A3FF]",
      dropShadow:
        "chrome:drop-shadow-[0px_0px_79px_rgba(0,163,255,0.4)] firefox:drop-shadow-[0px_0px_79px_rgba(0,163,255,0.4)]",
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(1);
  const [direction, setDirection] = useState(0);

  const handlePrev = () => {
    setDirection(-1);

    setCurrentIndex((prevIndex) => (prevIndex + 1) % drinks.length);
  };

  const handleNext = () => {
    setDirection(1);
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + drinks.length) % drinks.length,
    );
  };

  const currentDrink = drinks[currentIndex];

  return (
    <section
      className="flex flex-col justify-center bg-black py-16"
      id="drinks"
    >
      <div className="flex max-w-[90rem] items-center self-center md:gap-4">
        {/* Previous Drink */}
        <AnimatePresence mode="wait">
          <motion.div
            key={currentIndex}
            onClick={handleNext}
            className="my-auto flex min-w-[240px] cursor-pointer flex-wrap items-center self-stretch max-md:max-w-full"
            initial={{ opacity: 0, x: direction * -100 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: direction * 100 }}
            transition={{ duration: 0.5 }}
          >
            <DrinkCard
              drink={drinks[(currentIndex - 1 + drinks.length) % drinks.length]}
            />
          </motion.div>
        </AnimatePresence>

        {/* Left Arrow */}
        <ArrowButton onClick={handleNext} aria-label="Nächster drink" />

        {/* Current Drink with Description and Animation */}
        <AnimatePresence mode="wait">
          <motion.div
            key={currentIndex}
            className="my-auto flex min-w-[240px] items-center gap-4 self-stretch max-lg:flex-col max-md:max-w-full"
            initial={{ opacity: 0, x: direction * -100 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: direction * 100 }}
            transition={{ duration: 0.5 }}
          >
            <div
              className={`my-auto flex w-[313px] min-w-[240px] flex-col items-center justify-center self-stretch whitespace-nowrap pb-6 text-center ${currentDrink.textColor}`}
            >
              <div className="group flex flex-col transition-all duration-700 sm:hover:-translate-y-10">
                <div className="text-h3">{currentDrink.name}</div>
                <img
                  className={`mt-2 aspect-[0.46] w-8/12 max-w-full self-center object-contain sm:w-[313px] ${currentDrink.dropShadow}`}
                  src={currentDrink.imageSrc}
                  alt={currentDrink.name + " Dose"}
                  loading="lazy"
                  draggable={false}
                />
              </div>
            </div>
            {currentDrink.description && (
              <div className="my-auto flex w-[380px] min-w-[240px] flex-col items-start space-y-10 self-stretch max-lg:hidden">
                <div className="flex w-[361px] max-w-full flex-col">
                  <div className="text-h3">{currentDrink.name}</div>
                  <div className="mt-4 text-pl">{currentDrink.description}</div>
                </div>
                <CustomButtonLink
                  inverted={true}
                  to={{
                    pathname: "/",
                    hash: "#kontakt",
                  }}
                >
                  Anfrage senden
                </CustomButtonLink>
              </div>
            )}
          </motion.div>
        </AnimatePresence>

        {/* Right Arrow */}
        <ArrowButton
          className="rotate-180"
          onClick={handlePrev}
          aria-label="Vorheriger drink"
        />

        {/* Next Drink */}
        <AnimatePresence mode="wait">
          <motion.div
            key={currentIndex}
            onClick={handlePrev}
            className="my-auto flex min-w-[240px] cursor-pointer flex-wrap items-center self-stretch max-md:max-w-full"
            initial={{ opacity: 0, x: direction * -100 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: direction * 100 }}
            transition={{ duration: 0.5 }}
          >
            <DrinkCard drink={drinks[(currentIndex + 1) % drinks.length]} />
          </motion.div>
        </AnimatePresence>
      </div>
      {currentDrink.description && (
        <div className="my-auto flex w-[380px] min-w-[240px] flex-col items-start space-y-10 self-center px-5 lg:hidden">
          <div className="flex w-[361px] max-w-full flex-col">
            <div className="text-h3">{currentDrink.name}</div>
            <div className="mt-4 text-pl">{currentDrink.description}</div>
          </div>
          <CustomButtonLink
            inverted={true}
            to={{
              pathname: "/",
              hash: "#kontakt",
            }}
          >
            Anfrage senden
          </CustomButtonLink>
        </div>
      )}
    </section>
  );
}

type DrinkCardProps = {
  drink: {
    name: string;
    textColor: string;
    dropShadow: string;
    imageSrc: string;
  };
};

function DrinkCard({ drink }: DrinkCardProps) {
  return (
    <div
      className={`group my-auto flex items-center gap-4 self-stretch whitespace-nowrap text-center transition-all duration-700 sm:hover:-translate-y-10 ${drink.textColor}`}
    >
      <div className="my-auto flex w-[312px] min-w-[240px] flex-col items-center justify-center self-stretch py-6 opacity-30 transition-all duration-700 group-hover:opacity-100">
        <div className="text-h3">{drink.name}</div>
        <img
          className={`aspect-[0.46] w-8/12 object-contain sm:w-[313px] ${drink.dropShadow}`}
          src={drink.imageSrc}
          alt={drink.name + " Dose"}
          loading="lazy"
          draggable={false}
        />
      </div>
    </div>
  );
}

function ArrowButton({
  className,
  ...props
}: ButtonHTMLAttributes<HTMLButtonElement>) {
  return (
    <button className={`${className} z-10`} {...props}>
      <svg
        className="max-sm:w-[0.875rem]"
        xmlns="http://www.w3.org/2000/svg"
        width="27"
        height="51"
        viewBox="0 0 27 51"
        fill="none"
      >
        <path
          d="M25 48.5L2 25.5L25 2.5"
          stroke="white"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </button>
  );
}
