import { Link } from "@remix-run/react";
import { CustomButton } from "~/components/ui/CustomButton";

export function Kontakt() {
  return (
    <section
      className="mx-auto flex max-w-[75rem] scroll-mt-28 justify-center px-5 max-md:flex-col md:space-x-10 lg:space-x-28"
      id="kontakt"
    >
      <div className="flex-1">
        <h3 className="text-m-h2 md:text-h2">INTERESSE GEWECKT?</h3>
        <p className="text-m-pm md:text-pl">
          Hast du einen Supermarkt, Laden, Kiosk oder Späti und willst unsere
          Getränke anbieten? Schreib uns einfach eine kurze Nachricht. Wir
          freuen uns darauf, schon bald auch in deinem Laden Erlebnisse aus der
          Dose anzubieten!
        </p>
      </div>
      <KontaktForm />
    </section>
  );
}

export default function KontaktForm() {
  /*todo check functionality*/

  return (
    <form
      method="post"
      action="https://updrinks.weclapp.com/webapp/seam/resource/rest/web2lead?charset=UTF-8"
      acceptCharset="UTF-8"
      className="md:text-p m flex w-full max-w-[593px] flex-1 flex-col pt-10 text-m-pm max-md:self-center"
      id="kontakt"
    >
      <div className="flex w-full flex-col max-md:max-w-full">
        <div className="flex w-full flex-col text-base font-medium max-md:max-w-full">
          <div className="flex w-full items-center gap-10 whitespace-nowrap max-sm:flex-col">
            <div className="my-auto flex w-full flex-col self-stretch">
              <label htmlFor="idWC_firstname">Vorname*</label>
              <input
                type="text"
                id="idWC_firstname"
                name="firstname"
                required
                maxLength={1000}
                className="min-h-[40px] w-full border-b border-white border-opacity-50 bg-transparent"
              />
            </div>

            <div className="my-auto flex w-full flex-col self-stretch">
              <label htmlFor="idWC_lastName">Nachname*</label>
              <input
                type="text"
                id="idWC_lastName"
                name="lastName"
                required
                maxLength={1000}
                className="min-h-[40px] w-full border-b border-white border-opacity-50 bg-transparent"
              />
            </div>
          </div>

          <div className="mt-6 flex w-full flex-col max-md:max-w-full">
            <label htmlFor="idWC_email">E-Mail Adresse*</label>
            <input
              type="email"
              id="idWC_email"
              name="email"
              required
              maxLength={1000}
              className="min-h-[40px] w-full border-b border-white border-opacity-50 bg-transparent"
            />
          </div>

          <div className="mt-6 flex w-full flex-col max-md:max-w-full">
            <label htmlFor="idWC_lead_topic">Worum geht es?*</label>
            <input
              type="text"
              id="idWC_lead_topic"
              name="lead_topic"
              required
              maxLength={1000}
              className="min-h-[40px] w-full border-b border-white border-opacity-50 bg-transparent"
            />
          </div>
        </div>

        <div className="mt-12 flex w-full flex-col max-md:mt-10 max-md:max-w-full">
          <label htmlFor="idWC_description">Deine Nachricht*</label>
          <textarea
            id="idWC_description"
            name="description"
            required
            rows={5}
            className="mt-2 min-h-[113px] w-full border border-solid border-white border-opacity-50 bg-transparent"
          />
        </div>
        <small className="mt-2 text-pxs max-md:max-w-full">
          *Pflichtfelder
        </small>
      </div>

      {/* Hidden fields */}
      <input type="hidden" name="honeypot" value="" />
      <input
        type="hidden"
        name="token"
        value="a9c80f3a-0db6-473e-a817-7d51d036d161"
      />

      <div className="mt-12 flex w-full items-center justify-between gap-x-6 max-md:mt-10 max-md:max-w-full max-sm:flex-col max-sm:space-y-6">
        <div className="my-auto flex gap-6">
          {/* Adjust checkbox style if needed */}
          {/*todo fix mobile safari checkbox*/}
          <input
            type="checkbox"
            name="agreedToPrivacyPolicy"
            id="agreedToPrivacyPolicy"
            required
            className="size-6 shrink-0 border border-solid border-white border-opacity-50 accent-white"
          />

          <label
            htmlFor="agreedToPrivacyPolicy"
            className="my-auto max-w-56 text-pm"
          >
            Die&nbsp;
            <Link
              to="/datenschutz"
              className="underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              Datenschutzerklärung
            </Link>
            &nbsp;habe ich zur Kenntnis genommen.*
          </label>
        </div>

        <CustomButton
          className="text-nowrap max-sm:w-full"
          type="submit"
          value="Send"
        >
          Nachricht absenden
        </CustomButton>
      </div>
    </form>
  );
}
